<template>
    <div>
        <DateRange
            v-if="tableId == 'liste_actes_client_area'"
            :start.sync="start_date"
            :end.sync="end_date"
            @submit="actesDate(start_date.toDateInputValue(), end_date.toDateInputValue())"
        />

        <CustomTable
            ref="table"
            :id_table="tableId"
            primaryKey="actes_id"
            :items="actes"
            sortBy="actes_date"
            sortDesc
            :hrefsRoutes="config_table_hrefs"
            :busy.sync="table_busy"
            :display_action_button="false"
            :checkboxes="false"
            :externSlotColumns="['notes.type_fonts']"
			:base-filters="filters"
            :hasExportOption="false"
            :showPagination="false"
        >
            <template v-slot:[`custom-slot-cell(notes.type_fonts)`]="{ data }">
                <div 
                    v-if="data.notes && Object.keys(data.notes.type_fonts).length > 0" 
                    class="previewColIdentifier"
					@click="catchColumnNotesShutter(data)"	
                >
                    <span 
                        v-for="(font, key) in data.notes.type_fonts"	
                        :key="key"
                    >
                        <font-awesome-icon :icon="font.font" /> {{ font.occurences }}
                    </span>
                </div>
            </template>
        </CustomTable>
    </div>
</template>


<script type="text/javascript">
    import ActeMixin from "@/mixins/Actes.js"
    import Navigation from "@/mixins/Navigation.js"
    import Shutter from "@/mixins/Shutter.js"
    import ShutterNotes from '@/mixins/shutters-manager/Notes.js'

	export default {
		name: "acteListe",
		mixins: [ActeMixin, Navigation, Shutter, ShutterNotes],
        props: {
            horse_id: { type: Number, default: 0 },
            actualTab: { type: String, default: '' }
        },
		data () {
			return {
                actes: [],
				table_busy: true, /* Définit si la table n'est pas encore chargée */
				start_date: new Date(new Date().setMonth(new Date().getMonth() - 3)),
                end_date: new Date(),
                
                events_tab: {
                    'TableAction::notifyActs': this.notify
                },

                /* Nom du cheval cliquable dans le tableau */
                config_table_hrefs: {
                    'horse_nom': {
                        routeUniqueName: 'horseFiche',
                        routeName: 'horseFiche',
                        params: {
                            horse_id: 'horse_id'
                        }
                    }
                }
			}
		},
        computed: {
            tableId() {
                return this.horse_id ? 'horse_actes' : 'liste_actes_client_area'
            },
            filters() {
                if(!this.actualTab) return {}

                return {
                    is_in_group: {
                        column: 'actetype.actestype_codegroupe',
                        operator: 'isEqualTo',
                        value: this.actualTab
                    }
                }
            }
        },
		created() {
            this.init_component()
		},
		methods: {
            async init_component() {
				this.actesDate(this.start_date.toDateInputValue(), this.end_date.toDateInputValue())
			},
			
			async actesDate(start_date, end_date) {
                this.table_busy = true

                if(this.horse_id > 0) {
                    const temp = await this.getActesFromHorse(this.horse_id).then(res => {
                        return this.formatActeDate(res)
                    })
                    this.actes = this.formatActesData(temp)
                }
                else {
                    this.actes = await this.getActes(start_date, end_date)
                }

                this.table_busy = false
            },

			async notify(ids) {
                this.notifyClient(ids)
                .then(() => {
                    this.successToast('toast.notif_sent')
                })
			},

            catchColumnNotesShutter(act) {
				this.handleNotePreview(act.notes.raw)
			}
		},
		components: {
			CustomTable: () => import('GroomyRoot/components/Table/CustomTable'),
            DateRange: () => import('GroomyRoot/components/Inputs/DateRange')
		}
	}
</script>